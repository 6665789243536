<template>
  <div class="c_report">
    <div class="stu-module-header">
      <div class="stu-module-title">
        开题报告（提交时间：{{ submitTime || "--" }}）
      </div>
      <el-button type="primary" v-show="this.status != 1" @click="submitBtn">
        提 交
      </el-button>
    </div>
    <el-table
      :data="datalist"
      v-loading="loading"
      :height="height"
      ref="tableRef"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="submitTime"
        label="提交时间"
        align="center"
        min-width="120px"
      >
      </el-table-column>
      <el-table-column
        prop="title"
        label="论文题目"
        align="center"
        min-width="120px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="describe"
        label="文献综述"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="checkStatus"
        label="审核状态"
        align="center"
        min-width="100px"
        :formatter="checkStatusFM"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="120px">
        <template slot-scope="scope">
          <el-button type="primary" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>

    <el-dialog :visible.sync="dialog" title="开题报告详情" width="45%">
      <el-form :model="selectRow" label-width="110px" class="reportDetail_form">
        <el-form-item label="论文题目：" :required="!detail_ ? true : false">
          <!-- <span v-if="selectRow.title">{{ selectRow.title }}</span> -->
          <el-input
            v-model="selectRow.title"
            v-if="!detail_"
            placeholder="请输入"
          ></el-input>
          <span v-else>{{ selectRow.title }}</span>
        </el-form-item>
        <el-form-item label="提交时间：" v-if="selectRow.submitTime">
          <span>{{ selectRow.submitTime }}</span>
        </el-form-item>
        <el-form-item label="文献综述：" :required="!detail_ ? true : false">
          <!-- <span v-if="selectRow.describe">{{ selectRow.describe }}</span> -->
          <el-input
            v-if="!detail_"
            v-model="selectRow.describe"
            placeholder="请输入"
          ></el-input>
          <span v-else>{{ selectRow.describe }}</span>
        </el-form-item>
        <el-form-item label="内容：" :required="!detail_ ? true : false">
          <!-- <span v-if="selectRow.content">{{ selectRow.content }}</span> -->
          <el-input
            type="textarea"
            v-if="!detail_"
            v-model="selectRow.content"
            placeholder="请输入"
          ></el-input>
          <span v-else>{{ selectRow.content }}</span>
        </el-form-item>
        <el-form-item label="审核评语：" v-if="selectRow.comment">
          <span>{{ selectRow.comment }}</span>
        </el-form-item>
        <el-form-item label="审核状态：" v-if="selectRow.checkStatuss">
          <span style="color: #ff9b22">{{ selectRow.checkStatuss }}</span>
        </el-form-item>
        <el-form-item label="审核时间：" v-if="selectRow.checkTime">
          <span>{{ selectRow.checkTime }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-if="!detail_"
          @click="submit"
          :loading="btnLoading"
        >
          提 交
        </el-button>
        <el-button type="primary" v-if="detail_" @click="dialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  QueryEduProByStuId,
  QueryBatchByStudentId,
  QueryEduProById,
  InsertSelective,
} from "@/libs/api/topic";
export default {
  name: "c_report",
  data() {
    return {
      dialog: false,
      btnLoading: false,
      loading: false,
      height: window.innerHeight - 300,
      tableData: [],
      pageSize: 10,
      current: 1,
      status: 0,
      total: 0,
      submitTime: null,
      selectRow: {
        title: "",
        describe: "",
        content: "",
      },
      detail_: false,
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.getSubTime();
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      QueryEduProByStuId({ studentId: this.user.id })
        .then((res) => {
          this.tableData = res.data;
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].checkStatus === 1) {
              this.status = 1;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getSubTime() {
      QueryBatchByStudentId().then((res) => {
        this.submitTime = `${res.data.questionStartTime} - ${res.data.questionEndTime}`;
      });
    },
    detail(row) {
      if (row) {
        this.dialog = true;
        this.detail_ = true;
        QueryEduProById({ id: row.id }).then((res) => {
          this.selectRow = res.data;
        });
      }
    },
    submitBtn() {
      this.dialog = true;
      this.selectRow = {};
      this.detail_ = false;
    },
    submit() {
      if (this.selectRow) {
        const { id, title, describe, content } = this.selectRow;
        if (!title) return this.$message.warning("请输入论文题目");
        if (!describe) return this.$message.warning("请输入文献综述");
        if (!content) return this.$message.warning("请输入内容");
        let data = {
          title,
          describe,
          content,
        };
        if (id) data.id = id;
        this.btnLoading = true;
        InsertSelective(data)
          .then((res) => {
            this.btnLoading = false;
            if (res.code === 2000) {
              this.dialog = false;
              this.init();
            }
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    checkStatusFM(row) {
      if (row) {
        const status = row.checkStatus;
        return status === 0
          ? "待审核"
          : status === 1
          ? "审核通过"
          : status === 2
          ? "驳回"
          : "";
      }
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
    },
  },
};
</script>

<style scoped lang="less">
.c_report {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    margin-bottom: 20px;
  }
  .reportDetail_form {
    span {
      font-weight: bold;
    }
  }
}
</style>
<style lang="less">
.c_report {
  .reportDetail_form {
    .el-form-item {
      .el-form-item__label {
        font-weight: bold;
      }
    }
  }
}
</style>
